import {InputComponent} from "./InputComponent";
import {ButtonComponent} from "./ButtonComponent";
import React from "react";
import {sendMessage} from "../../utils/formUtil";

const ContactFormComponent = () => {
    const [formData, changeFormData] = React.useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    })
    const [haveError, changeHaveError] = React.useState(false);
    const [isSuccess, changesSuccess] = React.useState(false);

    const sendEmail = () => {
        sendMessage('contacts', {...formData})
    }

    const send = (e: any) => {
        e.preventDefault();
        if (formData.email && formData.phone && formData.name && formData.message) {
            sendEmail()
            changesSuccess(true)
            changeHaveError(false)
        } else {
            changeHaveError(true)
        }
    }

    if (isSuccess) {
        return <div className="align-center size-3 text-white p-t-lg p-b-lg">
            <div className="text-primary">Gracias por contactarnos</div>
            <div className="size-default line-height-small">En la brevedad uno de nuestros asesores se pondrá en contacto con usted</div>
        </div>
    }

    return <div className="container">
        <form className="width-100 col-s12 p-b rent-form row" onSubmit={send}>
            <div className="col-s6 p-l-none p-r-none  p-t-s p-b-s">
                <InputComponent
                    label="teléfono"
                    valueId="phone"
                    value={formData.phone}
                    onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                    placeholder="Ejm: 09991112223"
                />
            </div>
            <div className="col-s6 p-l-s p-r-none p-t-s p-b-s">
                <InputComponent
                    label="email"
                    valueId="email"
                    value={formData.email}
                    onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                    placeholder="Ejm: email@rentacar.com.ec"
                />
            </div>
            <div className="col-s12 m-t p-b-s p-l-none p-r-none">
                <InputComponent
                    label="Nombre"
                    valueId="name"
                    value={formData.name}
                    onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                    placeholder="Ejm: Pedro Aznar García"
                />
            </div>
            <div className="col-s12 m-t p-b-s p-l-none p-r-none">
                <InputComponent
                    label="Mensaje"
                    valueId="message"
                    value={formData.message}
                    textArea={true}
                    onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                    placeholder="Ingrese su mensaje"
                />
            </div>
            {haveError &&
                <div className="col-s12">
                    <div className="size-small bg-danger bg-lighten-1 text-white radius-default line-height-default m-t m-b p-l p-r">*Información incompleta</div>
                </div>
            }
            <div className="width-100 p-b-s">
                <ButtonComponent formButton={true} extraButtonClass="m-t" buttonText="Contactar" buttonType="border-primary" textClass="text-white p-l p-r" />
            </div>
        </form>
    </div>
}

export default ContactFormComponent
