import React from "react";
import {InputComponent} from "./InputComponent";
import {SelectComponent} from "./SelectComponent";
import DateRangeComponent from "./DateRangeComponent";
import {ButtonComponent} from "./ButtonComponent";
import VEHICLES from "../../data/vehicles";
import {sendMessage} from "../../utils/formUtil";

interface IRentForm {
    vehicleActive?: boolean
    activeVehicleName?: string
}

const RentFormComponent = (props: IRentForm) => {
    const [haveError, changeHaveError] = React.useState(false);
    const [isSuccess, changesSuccess] = React.useState(false);
    const [formData, changeFormData] = React.useState({
        name: '',
        phone: '',
        vehicle: ''
    })
    const [selectDate, changeSelectDate] = React.useState<Array<{startDate: Date | undefined; endDate: Date | undefined; key: string}>>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const sendEmail = () => {
        const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        sendMessage(
            'vehicles',
            {
                ...formData,
                from: selectDate[0]?.startDate?.toLocaleString('ec-MX', dateFormat as any) || '',
                to: selectDate[0]?.endDate?.toLocaleString('ec-MX', dateFormat as any) || ''
        })
    }

    const send = (e: any) => {
        e.preventDefault();
        if ((formData.vehicle || props.vehicleActive) && formData.phone && formData.name) {
            sendEmail()
            changesSuccess(true)
            changeHaveError(false)
        } else {
            changeHaveError(true)
        }
    }
    React.useEffect(() => {
        if (props.activeVehicleName) {
            changeFormData({...formData, vehicle: props.activeVehicleName})
        }
    }, [props.activeVehicleName])
    if (isSuccess) {
        return <div className="align-center size-3 text-white p-t-lg">
            <div className="text-primary">Gracias por su reserva</div>
            <div className="size-default">En la brevedad uno de nuestros asesores se pondrá en contacto con usted</div>
        </div>
    }
    return <form className="width-100 p-t p-b rent-form" onSubmit={send}>
        <div className="width-100 p-t-s p-b-s">
            <InputComponent
                label="teléfono"
                valueId="phone"
                value={formData.phone}
                onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                placeholder="Ejm: 09991112223"
            />
        </div>
        <div className="width-100 p-t-s p-b-s">
            <InputComponent
                label="nombre"
                valueId="name"
                value={formData.name}
                onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                placeholder="Ejm: Emilia Vásquez"
            />
        </div>
        {!props.vehicleActive &&
        <div className="width-100 p-t-s p-b-s">
            <SelectComponent
                options={VEHICLES.reduce((ac: any, vh) => {
                    return [...ac, ...vh.vehicles];
                }, []).map((vh: any) => ({
                    id: `${vh.brand} ${vh.model}`,
                    label: <span className="flex-row flex-middle">
                        <span className="p-r flex-center p-t-s">
                            <img src={vh.image.default} className="radius-small" style={{width: 42}} />
                        </span>
                        <span className="flex-column flex-left line-height-default">
                            <strong>{`${vh.brand} ${vh.model}`}</strong>
                            <small className="">Desde: {vh.price}$ el día</small>
                        </span>
                    </span>
                }))}
                valueId="vehicle"
                onChangeValue={(value, valueId) => changeFormData({...formData, [valueId]: value})}
                value={formData.vehicle}
                label="Vehículo"
                placeholder="Seleccione un modelo"
            />
        </div>
        }
        <div className="width-100 p-t-s p-b-s">
            <DateRangeComponent selectDate={selectDate} changeSelectDate={changeSelectDate} />
        </div>
        {haveError &&
            <div className="size-small bg-danger bg-lighten-1 text-white radius-default line-height-default m-t m-b p-l p-r">*Información incompleta</div>
        }
        <div className="width-100 p-t-s p-b-s">
            <ButtonComponent formButton={true} buttonText="Alquilar el vehículo" buttonType="border-primary" textClass="text-white p-l p-r" />
        </div>
    </form>
}

export default RentFormComponent
