import React from "react";
import ContactFormComponent from "../global/ContactFormComponent";

interface IContactProps {
    contactOpen: boolean
    changeContactOpen: (isOpen: boolean) => void
}

const ModalContactComponent = (props: IContactProps) => {
    const close = () => {
        props.changeContactOpen(false)
    }

    return <div className="contact-modal">
        {props.contactOpen &&
            <div className="modal-content">
                <div className="close-bg" onClick={close} />
                <div className="bg-white form-area radius-big flex-row">
                    <div className="block-area flex-column flex-075 flex-center relative">
                        <a className="close-contact size-2" onClick={close}><i className="icon-close" /></a>
                        <iframe
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d249.36247402278136!2d-78.4768911548914!3d-0.17558985956724546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sec!4v1646247901940!5m2!1ses-419!2sec"
                            aria-label="Wm Rentacar"
                            style={{width: '100%', height: '100%'}}
                        />
                    </div>
                    <div className="block-area flex-column bg-gray p-all flex-1 form-content">
                        <p className="strong text-white line-height-small m-t">
                            Wm Rent a Car - Alquiler Autos Quito Ecuador - Ecuador
                        </p>
                        <p className="text-white p-t p-b line-height-small">
                            Edificio Genesis - Gonzalo Serrano E10-62, y 6 de Diciembre, Quito - Ecuador
                        </p>
                        <ContactFormComponent />
                    </div>
                </div>
            </div>
        }
    </div>
}

export default ModalContactComponent;
