import React from "react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import map from '../../assets/icons/map.svg';
import {addBodyClass, removeBodyClass} from "../../utils/BodyClassUtil";

interface IDate {startDate: Date | undefined; endDate: Date | undefined; key: string}

interface IDateRangeProps {
    selectDate:  Array<IDate>
    changeSelectDate: (date: Array<IDate>) => void
}

const DateRangeComponent = (props: IDateRangeProps) => {
    const [isOpen, changeIsOpen] = React.useState(false);
    const [state, setState] = React.useState<Array<{startDate: Date | undefined; endDate: Date | undefined; key: string}>>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const closeRange = () => {
        changeIsOpen(false)
        removeBodyClass('open-date-picker')
        setState(props.selectDate);
    }

    const onSelectData = (item: any) => {
        setState([item.selection])
    }

    const formatDate = (date: Date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    return <div className="date-range">
        <div onClick={() => {
                if (!isOpen) {
                    addBodyClass('open-date-picker')
                    changeIsOpen(true)
                    setState(props.selectDate);
                } else {
                    closeRange()
                }
             }}
             className="date-range-button flex-column bg-gray bg-lighten-1 p-t-s p-b-s p-l p-r radius-small cursor-pointer"
        >
            <div className="flex-row label text-uppercase">
                <span className="text-white light">Fechas</span>
            </div>
            <div className="flex-row width-100">
                <div className="flex-column flex-1 text-white size-small">
                    {props.selectDate[0].startDate && props.selectDate[0].endDate ? `desde ${formatDate(props.selectDate[0].startDate)} - hasta ${formatDate(props.selectDate[0].endDate)}` : 'Selecciona las fechas'}
                </div>
                <div className="flex-column">
                    <img src={map} alt=""/>
                </div>
            </div>
        </div>
        {isOpen &&
            <div className="range-content">
                <div className="close-bg" onClick={closeRange} />
                <div className="bg-white picker-area">
                    <DateRange
                        minDate={new Date()}
                        editableDateInputs={true}
                        onChange={onSelectData}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                    />
                    <div className="align-center">
                        <a
                            onClick={() => {
                                props.changeSelectDate(state)
                                changeIsOpen(false)
                                removeBodyClass('open-date-picker')
                            }}
                           className="text-primary p-all block">
                            Seleccionar estas fechas
                        </a>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default DateRangeComponent;
