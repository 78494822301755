export type IResponsiveType = 'large' | 'medium' | 'small';

const defaultWindow: any = {
    addEventListener: (event: any, method: any): any => null,
    document: {
        createElement: ''
    },
    innerHeight: 0,
    innerWidth: 0,
    location: {
        hostname: '',
        href: '',
        port: '',
        search: ''
    },
    removeEventListener: (method: any): any => null,
    scrollTop: (x: any, y: any): any => null
};

const defaultDocument: any = {
    body: {
        classList: {
            add: null,
            remove: null
        }
    }
};

export const clientWindow = typeof window !== 'undefined' ? window : defaultWindow;
export const clientDocument = typeof document !== 'undefined' ? document : defaultDocument;

const smallScreen = 769;
const mediumScreen = 1023;
const largeScreen = 1299;

export const getResponsiveType = (): IResponsiveType => {
    const windowWidth = clientWindow.innerWidth || largeScreen;
    switch (true) {
        case windowWidth <= smallScreen:
            return 'small';
        case windowWidth <= mediumScreen:
            return 'medium';
        default:
            return 'large';
    }
};
