import Sedan from '../assets/icons/sedan.svg';
import Truck from '../assets/icons/camionetas.svg';
import SUV from '../assets/icons/suv.svg';
import Premium from '../assets/icons/gama_alta.svg';
import Van from '../assets/icons/van.svg';
import Automatic from '../assets/icons/automatic.svg';

export interface IVehicle {
    image: any;
    model: string;
    brand: string;
    features: string[];
    price: number
}

interface IVehicleCategory {
    name: string;
    icon: any;
    vehicles: IVehicle[];
}

const VEHICLES: IVehicleCategory[] = [
    {
        name: 'Sedanes',
        icon: Sedan,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_beat.jpg'),
                model: 'Beats',
                price: 55,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sail.png'),
                model: 'Sail',
                price: 60,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Nissan',
                image: require('../assets/vehicles/nissan_versa.jpg'),
                model: 'Versa',
                price: 65,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Kia',
                image: require('../assets/vehicles/kia_rio_automatico.jpg'),
                model: 'Rio',
                price: 75,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
        ]
    },
    {
        name: 'Camionetas',
        icon: Truck,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_dmax.png'),
                model: 'Dmax 4x4',
                price: 95,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Mazda',
                image: require('../assets/vehicles/mazda_bt50.png'),
                model: 'Bt 50 4x4',
                price: 95,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'JAC',
                image: require('../assets/vehicles/jac_4x4.jpg'),
                model: '4x4',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Great Wall',
                image: require('../assets/vehicles/great_wall_4x4.jpg'),
                model: '4x4',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'ZX AUTO',
                image: require('../assets/vehicles/zx_auto_terralord.jpg'),
                model: 'Terralord 4x4',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            }
        ]
    },
    {
        name: 'SUV',
        icon: SUV,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sz.png'),
                model: 'Sz',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'GAC',
                image: require('../assets/vehicles/gac_gs3_automatico.jpg'),
                model: 'GS3 Automatico',
                price: 95,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_captiva.jpg'),
                model: 'Captiva',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_tracker.jpg'),
                model: 'Traker',
                price: 85,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                ]
            }
        ]
    },
    {
        name: 'Gama Alta',
        icon: Premium,
        vehicles: [
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_fortuner.png'),
                model: 'Fortuner',
                price: 130,
                features: [
                    'Aire Acondicionado',
                    '7 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_new_fortuner.jpg'),
                model: 'New Fortuner',
                price: 170,
                features: [
                    'Aire Acondicionado',
                    '7 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            }
        ]
    },
    {
        name: 'Van',
        icon: Van,
        vehicles: [
            {
                brand: 'Hyundai',
                image: require('../assets/vehicles/hyundai_h1.png'),
                model: 'H1',
                price: 140,
                features: [
                    'Aire Acondicionado',
                    '12 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Cinascar',
                image: require('../assets/vehicles/cinascar_cherry.png'),
                model: 'Cherry',
                price: 110,
                features: [
                    'Aire Acondicionado',
                    '10 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Volskwagen',
                image: require('../assets/vehicles/volskwagen_crafter.png'),
                model: 'Crafter',
                price: 190,
                features: [
                    'Aire Acondicionado',
                    '17 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            }
        ]
    },
    {
        name: 'Automáticos',
        icon: Automatic,
        vehicles: [
            {
                brand: 'Kia',
                image: require('../assets/vehicles/kia_rio_automatico.jpg'),
                model: 'Rio',
                price: 75,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sz.png'),
                model: 'SZ',
                price: 90,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
            {
                brand: 'GAC',
                image: require('../assets/vehicles/gac_gs3_automatico.jpg'),
                model: 'GS3',
                price: 95,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_fortuner.png'),
                model: 'Fortuner',
                price: 130,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción automática'
                ]
            },
        ]
    }
]

export default VEHICLES;
