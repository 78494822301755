export const addBodyClass = (className: string) => {
  if (typeof window !== 'undefined') {
    document.body.classList.add(className)
  }
}

export const removeBodyClass = (className: string) => {
  if (typeof window !== 'undefined') {
    document.body.classList.remove(className)
  }
}

export const scrollContent = () => {
  setTimeout(() => {
    const container = document.getElementById('scrollLayout')
    if (!!container?.scrollTop) {
      container.scrollTop = 0
    }
    // eslint-disable-next-line no-magic-numbers
  }, 500)
}
