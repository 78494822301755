import mainImage from '../../assets/icons/alquiler_autos.png'
import rentACar from '../../assets/icons/wm_rent_a_car_alquile_de_autos.svg'

const Home = () => {
    return <div className="home" id="homeBanner">
        <div className="home-bg flex-row flex-middle flex-no-wrap">
            <div className="image-container flex-column" ><img src={mainImage} alt=""/></div>
            <div className="flex-column align-right p-r-lg content-area">
                <div className="brand-area">
                    <img className="brand" src={rentACar} alt=""/>
                </div>
                <div className="brand-description">
                    <div>
                        <h2 className="main-title line-height-small p-b light">
                            Renta tu auto en Quito
                        </h2>
                        <p className="p-b-lg m-b-lg size-4 line-height-default light">
                            Viaja donde quieras y cuando
                            quieras, tenemos los mejores vehículos
                            a los precios más convenientes
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Home;
